$platform-name: "christshospital";

$brand-primary: #001733;
$brand-secondary: #15668c;
$donate-colour: $brand-secondary;

$grey-dark: #1e1e1e;
$grey-lightest: #f0f0f0;

$spacer: 1rem;

$link-colour: $brand-secondary;

$container-max-width: 1170px;
$gap-width: 40px;

$border-radius: 0;

$logo: "logo.png";
$logo-retina: $logo;
$logo-width: 350px;
$logo-height: 75px;

$font-family-base: 'Arial', sans-serif;
$font-size-base: 1rem;
$body-colour: #1e1e1e;
$headings-font-family: $font-family-base;
$headings-colour: $body-colour;
$headings-text-transform: none;
$headings-font-weight: 700;
$headings-letter-spacing: initial;

$btn-font-family: $headings-font-family;
$btn-text-transform: uppercase;
$btn-font-weight: 700;
$btn-font-size: 1em;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.75em;

$card-hover-heading-colour: $brand-primary;

$menu-admin-background-colour: $brand-primary;
$menu-admin-link-colour: text-contrast($brand-primary);
$menu-admin-font-size: 0.75rem;
$menu-admin-align-items: left;

$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;

$tagline-enabled: false;

$header-search-enabled: true;
$header-search-input-max-width: 200px;
$header-search-margin-right: 0;
$header-search-input-margin-right: 0;
$header-search-input-border-radius: 0;
$header-search-input-border-width: 0;
$header-search-input-background-colour: rgba(#fff, 0.2);
$header-search-input-placeholder-colour: text-contrast($menu-admin-background-colour);
$header-search-button-icon-colour: text-contrast($menu-admin-background-colour);

$header-search-button-border-radius: 0;
$header-search-button-background-colour: transparent;

$cta-btn-gap: 1rem;

$nav-text-transform: none;
$nav-normal-max-width: 100%;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-margin-bottom: 20px;
$nav-top-level-item-padding: 1rem 1.5rem;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-submenu-item-padding: 10px 1.5rem;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-hover-background-colour: transparent;
$nav-submenu-item-hover-colour: $brand-primary;

$page-title-colour: $headings-colour;

$carousel-details-background-colour: rgba(#fff, 0.95);
$carousel-details-padding: 2rem;
$carousel-details-max-width: 40%;
$carousel-details-margin-y: 2rem;
$carousel-details-margin-x: 2rem;
$carousel-heading-colour: $headings-colour;
$carousel-heading-font-size: 1.5em;
$carousel-summary-enabled: true;
$carousel-summary-colour: $body-colour;
$carousel-read-more-enabled: false;

$home-intro-font-family: $headings-font-family;
$home-intro-font-size: 2rem;
$home-intro-line-height: 1.45em;
$home-intro-font-weight: 700;
$home-intro-max-width: 940px;
$home-intro-background-colour: transparent;
$home-intro-margin-top: 4rem;
$home-intro-padding-y: 0;

$shop-enabled: true;
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;

$blog-details-enabled: true;
$blog-details-profile-pic-enabled: false;

$home-features-padding-top: calc(4rem - (#{$gap-width} / 2));
$home-features-padding-bottom: $home-features-padding-top;

$impact-stats-max-width: $container-max-width;
$impact-stats-background-colour: $grey-lightest;
$impact-stats-padding-y: 4rem;
$impact-stats-margin-bottom: 4rem;
$impact-stats-heading-font-size: 2.5rem;
$impact-stats-heading-margin-bottom: 1rem;
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-figure-font-size: 5rem;
$impact-stats-summary-font-size: 1rem;

$quick-giving-donation-amount-figure-font-size: 1.375rem;
$quick-giving-donation-amount-background-colour: #fff;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;

$home-quick-giving-max-width: $container-max-width;
$home-quick-giving-padding-y: 4rem;
$home-quick-giving-margin-bottom: 4rem;
$home-quick-giving-heading-font-size: 2.5rem;
$home-quick-giving-donation-amount-background-colour: #fff;
$home-quick-giving-donation-amount-selected-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;

$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 4rem;

$feeds-title-font-size: 2.5rem;

$card-border: 0;
$card-image-border-radius: $border-radius;
$card-details-padding: 1.5rem;
$card-heading-colour: $headings-colour;
$card-heading-font-size: 1.5em;
$card-footer-background-colour: transparent;
$card-footer-padding: 0 $card-details-padding;

$card-side-by-side-image-width: 40%;
$card-side-by-side-grow-image: false;

$footer-boxes-count: 5;
$footer-prefab: 4;
$footer-padding-y: 3rem;
$footer-background-colour: $grey-lightest;
$footer-link-colour: $body-colour;
$footer-font-size: 0.875rem;
$footer-headings-font-size: 1.375rem;

$newsletter-background-colour: transparent;
$newsletter-heading-font-size: $footer-headings-font-size;
$newsletter-heading-text-align: left;

$sidebar-adjacent-width: 260px;
$sidebar-adjacent-gap: 120px;
