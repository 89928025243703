#menuAdmin {
  li {
    margin: 0;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  .menuAdminSearch {
    order: 5;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;

    button {
      position: absolute;
      right: 0;
    }
  }

  #siteSearch {
    max-width: $header-search-input-max-width;
  }

  .socialIcons {
    order: 6;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.pageHeader .headerContent .mainCallToAction {
  margin-left: auto;
}

.homepage-quickgiving--container {
  flex-direction: column;
}

.homepage-quickgiving--copy {
  margin: 0 0 1rem;
}

// Footer

.Footer-Legal {
  text-align: center;
}

// Header mobile fixes
@media (max-width: map-get($breakpoints, sm)) {
  #menuAdmin .menuAdminSearch {
    display: none;
  }
  #menuAdmin .socialIcons {
    display: none;
  }
}